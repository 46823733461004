<template>
  <div
      class="root"
      ref="root">
    <div class="container">

      <Transition
          enter-active-class="animated slideInRight"
          leave-active-class="animated slideOutLeft">
        <section
            class="card-container"
            :key="state.selectableCards">
            <img src="../src/assets/img/logo.png" class="logo" />
      <h1>Was ist ihr Anliegen?</h1>
      <div class="breadcrumbs">{{ masterSubject }}</div>
          <div
              class="card back"
              v-if="state.historyCards.length > 0"
              @click="handleHistory()">
            zurück
          </div>
          <div
            class="card"
            v-for="card in state.selectableCards"
            :key="card.subject"
            @click="setCards(card)">
          {{ card.subject }}
        </div>
        </section>
      </Transition>
      <Transition
          enter-active-class="animated slideInRight"
          leave-active-class="animated slideOutLeft">
      <form
          class="form"
          v-if="state.form.inputs"
          @submit.prevent="sendForm()">
        <h2>{{ state.form.subject }}</h2>
        <div
            v-for="input in state.form.inputs"
            class="input-group"
            :key="input.label">
          <label v-if="!input.group && !input.dependsOn">
            {{ input.label }}
            <span class="mandatory" v-if="!input.optional">*</span>
            <input v-if="input.type !== 'select' && input.type !== 'textarea'" :type="input.type" v-model="input.value" :required="input.optional ? false : true" />
            <select v-if="input.type === 'select' && input.type !== 'textarea'" v-model="input.value">
              <option disabled selected>Bitte wählen</option>
              <option v-for="option in input.options" :key="option">{{ option }}</option>
            </select>
            <textarea v-if="input.type !== 'select' && input.type === 'textarea'" v-model="input.value" :required="input.optional ? false : true" />
          </label>
          <div class="form-group" v-if="input.group || (input.dependsOn && state.form.inputs[input.dependsOn.idx].value === input.dependsOn.value)">
            <h3>{{ input.title }}</h3>
            <div
                v-for="input in input.inputs"
                class="input-group"
                :key="input.label">
                <label>
                  {{ input.label }}
                  <span class="mandatory" v-if="!input.optional">*</span>
                  <input :type="input.type" v-model="input.value" :required="input.optional ? false : true" />
                </label>
            </div>
          </div>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="" id="privacy" name="privacy" required>
          <label class="form-check-label" for="privacy">
            Ich habe die <a href="https://www.hdi.de/datenschutz/" target="_blank">Datenschutzerklärung</a>
            zur Kenntnis genommen. Ich stimme zu, dass meine Angaben zur Kontaktaufnahme
            und für Rückfragen dauerhaft gespeichert werden.
          </label>
        </div>
        <button type="submit">Absenden</button>
      </form>
    </Transition>
    </div>
  </div>
</template>


<script>
import { onMounted, ref, reactive, computed } from 'vue'
import useEmitter from '@/composables/useEmitter'
import { appHeight, toggleFullscreen } from '@/tools.js'
import axios from 'axios'
import { createToast } from 'mosha-vue-toastify'
import 'mosha-vue-toastify/dist/style.css'

const btnSoundFile = require('@/assets/sounds/btn.mp3')
const btnSound = new Audio(btnSoundFile)

window.addEventListener('resize', appHeight)
appHeight()

export default {
  name: 'App',
  components: {

  },

  setup: () => {
    const emitter = useEmitter()
    const root    = ref(null)

    const state = reactive({
      selectableCards: null,
      historyCards: [],
      form: {},
      subjects: [],
      cards: [
        {
          subject: 'Meine persönlichen Daten ändern',
          cards: [
            {
              subject: 'Adressänderung',
              email: 'Vielen Dank für Ihre Mitteilung. Ihren Wunsch bearbeiten wir schnellstmöglich. Ggfls. Erhalten Sie einen Nachtrag zum Versicherungsschein.',
              inputs: [
                { label: 'Versicherungsnummer', type: 'text' },
                { label: 'Vor- und Nachname', type: 'text' },
                {
                  group: true,
                  title: 'Neue Anschrift',
                  inputs: [
                    { label: 'Strasse und Hausnummer', type: 'text' },
                    { label: 'Postleitzahl und Ort', type: 'text' }
                  ]
                },
                {
                  group: true,
                  title: 'Meine Kontaktdaten (Versicherungsnehmer)',
                  inputs: [
                    { label: 'Emailadresse', type: 'email' },
                    { label: 'Telefonnummer', type: 'text', optional: true }
                  ]
                }
              ]
            },
            {
              subject: 'Bankdatenänderung',
              email: 'Vielen Dank für Ihre Mitteilung. Ihren Wunsch bearbeiten wir schnellstmöglich. Ggfls. Erhalten Sie einen Nachtrag zum Versicherungsschein.',
              inputs: [
                { label: 'Versicherungsnummer', type: 'text' },
                { label: 'neue IBAN', type: 'text' },
                { label: 'neue BIC', type: 'text' },
                { label: 'neues kreditinstitut', type: 'text' },
                {
                  group: true,
                  title: 'Bei abweichendem KontoinhaberIn',
                  inputs: [
                    { label: 'Vor- und Nachname', type: 'text' },
                    { label: 'Strasse und Hausnummer', type: 'text' },
                    { label: 'Postleitzahl und Ort', type: 'text' }
                  ]
                },
                {
                  group: true,
                  title: 'Meine Kontaktdaten (Versicherungsnehmer)',
                  inputs: [
                    { label: 'Emailadresse', type: 'email' },
                    { label: 'Telefonnummer', type: 'text', optional: true }
                  ]
                }
              ]
            },
            {
              subject: 'Namensänderung',
              email: 'Vielen Dank für Ihre Mitteilung. Ihren Wunsch bearbeiten wir schnellstmöglich. Ggfls. Erhalten Sie einen Nachtrag zum Versicherungsschein.',
              inputs: [
                { label: 'Versicherungsnummer', type: 'text' },
                {
                  group: true,
                  title: 'Vorheriger Name',
                  inputs: [
                    { label: 'Vor- und Nachname', type: 'text' }
                  ]
                },
                {
                  group: true,
                  title: 'Neuer Name',
                  inputs: [
                    { label: 'Vor- und Nachname', type: 'text' }
                  ]
                },
                {
                  group: true,
                  title: 'Meine Kontaktdaten (Versicherungsnehmer)',
                  inputs: [
                    { label: 'Emailadresse', type: 'email' },
                    { label: 'Telefonnummer', type: 'text', optional: true }
                  ]
                }
              ]
            }
          ]
        },
        {
          subject: 'eVB- neues Fahrzeug zulassen',
          cards: [
            {
              subject: 'eVB per Email anfordern',
              email: 'Vielen Dank für die Anforderung einer eVB zur Zulassung Ihres neuen Fahrzeugs. Die eVB erhalten Sie spätestens am nächsten Werktag per Email. Bitte melden Sie sich spätestens direkt nach der Zulassung in unserer Agentur unter Telefon 030 208 987 800 um den gewünschten Versicherungsschutz zu beantragen. Die eVB bestätigt nur eine kurzfristige Haftpflichtdeckung.',
              inputs: [
                { label: 'Angabe der Nutzung', type: 'select', options: ['privat', 'gewerblich'], value: 'privat' },
                { label: 'Art des Kennzeichens', type: 'select', options: ['Amtliches Kennzeichen', 'Saisonkennzeichen', 'Kurzzeitkennzeichen'], value: 'Amtliches Kennzeichen' },
                { label: 'Art des Fahrzeugs', type: 'select', options: ['PKW', 'Motorrad', 'Leichtkraftroller bis 125 ccm', 'Leichtkrafträder bis 125 ccm', 'Trikes', 'Quads', 'Wohnmobil', 'Wohnwagen', 'Anhänger', 'Anhänger für landwirtschaftliche Zumaschine', 'Lieferwagen (Werkverkehr) bis 3,5 t', 'LKW (Werkverkehr) über 3,5 t'], value: 'PKW' },
                {
                  group: true,
                  title: 'Anschrift Versicherungsnehmer',
                  inputs: [
                    { label: 'Vor- und Nachname', type: 'text' },
                    { label: 'Strasse und Hausnummer', type: 'text' },
                    { label: 'Postleitzahl und Ort', type: 'text' }
                  ]
                },
                { label: 'Geburtsdatum des Versicherungsnehmer', type: 'date' },
                { label: 'Gibt es einen abweichenden Halter?', type: 'select', options: ['nein', 'ja'], value: 'nein' },
                {
                  // idx = depending array value (idx 5 = 'Gibt es einen abweichenden Halter?')
                  dependsOn: { idx: 5, value: 'ja' },
                  title: 'Abweichender Halter',
                  inputs: [
                    { label: 'Vor- und Nachname', type: 'text' },
                    { label: 'Strasse und Hausnummer', type: 'text' },
                    { label: 'Postleitzahl und Ort', type: 'text' }
                  ]
                },
                {
                  group: true,
                  title: 'Meine Kontaktdaten (Versicherungsnehmer)',
                  inputs: [
                    { label: 'Emailadresse', type: 'email' },
                    { label: 'Telefonnummer', type: 'text', optional: true }
                  ]
                }
              ]
            },
            {
              subject: 'eVB telefonisch erhalten',
              email: 'Vielen Dank für Ihr Vertrauen. Wir setzen uns spätestens am nächsten Werktag mit Ihnen in Verbindung.',
              inputs: [
                { label: 'Vor- und Nachname', type: 'text' },
                { label: 'Emailadresse', type: 'email' },
                { label: 'Telefonnummer', type: 'text' },
                { label: 'Wann sind Sie gut zu erreichen?', type: 'select', options: ['9-11 Uhr', '11-13 Uhr', '14-16 Uhr', '17-18 Uhr'], value: '9-11 Uhr' },
              ]
            }
          ]
        },
        {
          subject: 'Versicherungsnehmerwechsel Betriebsrente (bAV)',
          cards: [
            {
              subject: 'Mein neuer Arbeitgeber wird neuer Versicherungsnehmer',
              email: 'Vielen dank für Ihre Nachricht. Sie erhalten schnellstmöglich eine Email von uns mit den vorbereiteten Dokumenten zum Versicherungsnehmerwechsel.',
              inputs: [
                { label: 'Versicherungsnummer', type: 'text' },
                { label: 'Vor- und Nachname der versicherten Person', type: 'text' },
                { label: 'Vor- und Nachname neuer Versicherungsnehmer', type: 'text' },
                {
                  group: true,
                  title: 'Anschrift neuer Versicherungsnehmer',
                  inputs: [
                    { label: 'Strasse und Hausnummer', type: 'text' },
                    { label: 'Postleitzahl und Ort', type: 'text' }
                  ]
                },
                { label: 'Gewünschter Änderungsbeginn', type: 'date' },
                {
                  group: true,
                  title: 'Meine Kontaktdaten (Versicherungsnehmer)',
                  inputs: [
                    { label: 'Emailadresse', type: 'email' },
                    { label: 'Telefonnummer', type: 'text', optional: true }
                  ]
                }
              ]
            },
            {
              subject: 'Ich möchte den Vertrag (vorerst) privat weiterführen',
              email: 'Vielen dank für Ihre Nachricht. Sie erhalten schnellstmöglich eine Email von uns mit den vorbereiteten Dokumenten zum Versicherungsnehmerwechsel.',
              inputs: [
                { label: 'Versicherungsnummer', type: 'text' },
                { label: 'Vor- und Nachname der versicherten Person', type: 'text' },
                {
                  group: true,
                  title: 'Meine Anschrift',
                  inputs: [
                    { label: 'Strasse und Hausnummer', type: 'text' },
                    { label: 'Postleitzahl und Ort', type: 'text' }
                  ]
                },
                { label: 'Gewünschter Änderungsbeginn', type: 'date' },
                {
                  group: true,
                  title: 'Meine Kontaktdaten (Versicherungsnehmer)',
                  inputs: [
                    { label: 'Emailadresse', type: 'email' },
                    { label: 'Telefonnummer', type: 'text', optional: true }
                  ]
                }
              ]
            },
            {
              subject: 'Ich möchte mich zum Thema Versicherungsnehmerwechsel persönlich beraten lassen',
              email: 'Vielen dank für Ihre Nachricht. Sie erhalten schnellstmöglich einen Rückruf von uns.',
              inputs: [
                { label: 'Versicherungsnummer', type: 'text', optional: true },
                { label: 'Emailadresse', type: 'email' },
                { label: 'Telefonnummer', type: 'text' },
                { label: 'Wann sind Sie gut zu erreichen?', type: 'select', options: ['9-11 Uhr', '11-13 Uhr', '14-16 Uhr', '17-18 Uhr'], value: '9-11 Uhr' },
              ]
            }
          ]
        },
        {
          subject: 'Schaden melden',
          cards: [
            {
              subject: 'Schaden per Email melden',
              cards: [
                {
                  subject: 'KFZ',
                  email: 'Vielen Dank für die Meldung Ihres Schadens. Machen Sie dringend einige Fotos vom Schaden und beautragen Sie einen Kostenvoranschlag für die Reparatur. Erste Schadenminderungsmaßnahmen sollten selbstverständlich sofort erfolgen.',
                  inputs: [
                    { label: 'Schadenstag', type: 'date' },
                    { label: 'Schadensuhrzeit', type: 'time' },
                    {
                      group: true,
                      title: 'Schadensort',
                      inputs: [
                        { label: 'Strasse und Hausnummer', type: 'text' },
                        { label: 'Postleitzahl und Ort', type: 'text' }
                      ]
                    },
                    { label: 'Mein Kennzeichen', type: 'text' },
                    { label: 'Vor- und Nachname (Versicherungsnehmer)', type: 'text' },
                    { label: 'Art des Schadens', type: 'select', options: ['Glas', 'Hagel/ Sturm', 'KFZ- Haftpflichtschaden', 'sonstiger Schaden'] },
                    { label: 'kurze Schadenschilderung', type: 'textarea' },
                    {
                      group: true,
                      title: 'Meine Kontaktdaten',
                      inputs: [
                        { label: 'Emailadresse', type: 'email' },
                        { label: 'Telefonnummer', type: 'text', optional: true },
                      ]
                    },
                  ]
                },
                {
                  subject: 'Hausrat',
                  email: 'Vielen Dank für die Meldung Ihres Schadens. Machen Sie dringend einige Fotos vom Schaden und beautragen Sie einen Kostenvoranschlag für die Reparatur. Erste Schadenminderungsmaßnahmen sollten selbstverständlich sofort erfolgen.',
                  inputs: [
                    { label: 'Schadenstag', type: 'date' },
                    { label: 'Schadensuhrzeit', type: 'time' },
                    {
                      group: true,
                      title: 'Schadensort',
                      inputs: [
                        { label: 'Strasse und Hausnummer', type: 'text' },
                        { label: 'Postleitzahl und Ort', type: 'text' }
                      ]
                    },
                    { label: 'Versicherungsnummer', type: 'text' },
                    { label: 'Vor- und Nachname (Versicherungsnehmer)', type: 'text' },
                    { label: 'Art des Schadens', type: 'select', options: ['Feuer/ Explosion', 'Blitzschlag/ Überspannung', 'Einbruch/ Diebstahl/ Raub', 'Fahrraddiebstahl', 'Wasserschaden/ Rohrbruch', 'Sturm-/ Hagelschaden', 'sonstiges, z.B. Überflutung'] },
                    { label: 'kurze Schadenschilderung', type: 'textarea' },
                    {
                      group: true,
                      title: 'Meine Kontaktdaten',
                      inputs: [
                        { label: 'Emailadresse', type: 'email' },
                        { label: 'Telefonnummer', type: 'text', optional: true },
                      ]
                    },
                  ]
                },
                {
                  subject: 'Glas',
                  email: 'Vielen Dank für die Meldung Ihres Schadens. Machen Sie dringend einige Fotos vom Schaden und beautragen Sie einen Kostenvoranschlag für die Reparatur. Erste Schadenminderungsmaßnahmen sollten selbstverständlich sofort erfolgen.',
                  inputs: [
                    { label: 'Schadenstag', type: 'date' },
                    { label: 'Schadensuhrzeit', type: 'time' },
                    {
                      group: true,
                      title: 'Schadensort',
                      inputs: [
                        { label: 'Strasse und Hausnummer', type: 'text' },
                        { label: 'Postleitzahl und Ort', type: 'text' }
                      ]
                    },
                    { label: 'Versicherungsnummer', type: 'text' },
                    { label: 'Vor- und Nachname (Versicherungsnehmer)', type: 'text' },
                    { label: 'kurze Schadenschilderung', type: 'textarea' },
                    {
                      group: true,
                      title: 'Meine Kontaktdaten',
                      inputs: [
                        { label: 'Emailadresse', type: 'email' },
                        { label: 'Telefonnummer', type: 'text', optional: true },
                      ]
                    },
                  ]
                },
                {
                  subject: 'Wohngebäude',
                  email: 'Vielen Dank für die Meldung Ihres Schadens. Machen Sie dringend einige Fotos vom Schaden und beautragen Sie einen Kostenvoranschlag für die Reparatur. Erste Schadenminderungsmaßnahmen sollten selbstverständlich sofort erfolgen.',
                  inputs: [
                    { label: 'Schadenstag', type: 'date' },
                    { label: 'Schadensuhrzeit', type: 'time' },
                    {
                      group: true,
                      title: 'Schadensort',
                      inputs: [
                        { label: 'Strasse und Hausnummer', type: 'text' },
                        { label: 'Postleitzahl und Ort', type: 'text' }
                      ]
                    },
                    { label: 'Versicherungsnummer', type: 'text' },
                    { label: 'Vor- und Nachname (Versicherungsnehmer)', type: 'text' },
                    { label: 'Art des Schadens', type: 'select', options: ['Feuer/ Explosion', 'Blitzschlag/ Überspannung', 'Wasserschaden/ Rohrbruch', 'Sturm-/ Hagelschaden', 'sonstiges, z.B. Überflutung'] },
                    { label: 'kurze Schadenschilderung', type: 'textarea' },
                    {
                      group: true,
                      title: 'Meine Kontaktdaten',
                      inputs: [
                        { label: 'Emailadresse', type: 'email' },
                        { label: 'Telefonnummer', type: 'text', optional: true },
                      ]
                    },
                  ]
                },
                {
                  subject: 'Unfallversicherung',
                  email: 'Vielen Dank für die Meldung Ihres Schadens. Machen Sie dringend einige Fotos vom Schaden und beautragen Sie einen Kostenvoranschlag für die Reparatur. Erste Schadenminderungsmaßnahmen sollten selbstverständlich sofort erfolgen.',
                  inputs: [
                    { label: 'Schadenstag', type: 'date' },
                    { label: 'Schadensuhrzeit', type: 'time' },
                    {
                      group: true,
                      title: 'Schadensort',
                      inputs: [
                        { label: 'Strasse und Hausnummer', type: 'text' },
                        { label: 'Postleitzahl und Ort', type: 'text' }
                      ]
                    },
                    { label: 'Versicherungsnummer', type: 'text' },
                    { label: 'Vor- und Nachname der versicherten/ geschädigten Person', type: 'text' },
                    { label: 'kurze Schadenschilderung', type: 'textarea' },
                    {
                      group: true,
                      title: 'Meine Kontaktdaten',
                      inputs: [
                        { label: 'Emailadresse', type: 'email' },
                        { label: 'Telefonnummer', type: 'text', optional: true },
                      ]
                    },
                  ]
                },
                {
                  subject: 'Haftpflichtschaden',
                  email: 'Vielen Dank für die Meldung Ihres Schadens. Machen Sie dringend einige Fotos vom Schaden und beautragen Sie einen Kostenvoranschlag für die Reparatur. Erste Schadenminderungsmaßnahmen sollten selbstverständlich sofort erfolgen.',
                  inputs: [
                    { label: 'Schadenstag', type: 'date' },
                    { label: 'Schadensuhrzeit', type: 'time' },
                    {
                      group: true,
                      title: 'Schadensort',
                      inputs: [
                        { label: 'Strasse und Hausnummer', type: 'text' },
                        { label: 'Postleitzahl und Ort', type: 'text' }
                      ]
                    },
                    { label: 'Versicherungsnummer', type: 'text' },
                    { label: 'Vor- und Nachname (Versicherungsnehmer)', type: 'text' },
                    { label: 'Art des Schadens', type: 'select', options: ['Versicherungsnehmer', 'Ehepartner/ Lebensgefährte', 'Kind', 'Haustier'] },
                    { label: 'kurze Schadenschilderung', type: 'textarea' },
                    {
                      group: true,
                      title: 'Meine Kontaktdaten',
                      inputs: [
                        { label: 'Emailadresse', type: 'email' },
                        { label: 'Telefonnummer', type: 'text', optional: true },
                      ]
                    },
                  ]
                },
              ]
            },
            {
              subject: 'Schaden telefonisch melden',
              email: 'Vielen Dank für die Meldung Ihres Schadens. Machen Sie dringend einige Fotos vom Schaden und beauftragen Sie einen Kostenvoranschlag für die Reparatur. Erste Schadenminderungsmaßnahmen sollten selbstverständlich sofort erfolgen. Sie erhalten schnellstmöglich einen Rückruf aus unserer Agentur.',
              inputs: [
                { label: 'Versicherungsnummer', type: 'text', optional: true },
                { label: 'Art der Versicherung', type: 'select', options: ['Haftpflichtversicherung', 'KFZ-Versicherung', 'Hausratversicherung', 'Wohngebäudeversicherung', 'Glasversicherung', 'Unfallversicherung'], value: 'nein' },
                {
                  group: true,
                  title: 'Meine Kontaktdaten',
                  inputs: [
                    { label: 'Vor- und Nachname', type: 'text' },
                    { label: 'Emailadresse', type: 'email' },
                    { label: 'Telefonnummer', type: 'text', optional: true },
                  ]
                },
                { label: 'Wann sind Sie gut zu erreichen?', type: 'select', options: ['9-11 Uhr', '11-13 Uhr', '14-16 Uhr', '17-18 Uhr'], value: '9-11 Uhr' },
              ]
            }
          ]
        },
        {
          subject: 'Versicherungsschutz erweitern',
          cards: [
            {
              subject: 'Versicherungsschutz ändern',
              email: 'Vielen Dank für Ihre Nachricht. Wir setzen uns schnellstmöglich telefonisch oder per Email zu Ihrem Wunsch mit Ihnen in Verbindung.',
              inputs: [
                { label: 'Art der Versicherung', type: 'select', options: ['Haftpflicht', 'KFZ', 'Tier', 'Hausrat', 'Wohngebäude', 'Unfallversicherung', 'Rechtsschutz', 'Firmenversicherung', 'Rentenversicherung', 'sonstiges'], value: 'nein' },
                { label: 'Versicherungsnummer', type: 'text', optional: true },
                { label: 'Vor- und Nachname', type: 'text' },
                { label: 'Strasse und Hausnummer', type: 'text' },
                { label: 'Postleitzahl und Ort', type: 'text' },
                { label: 'Was soll geändert werden', type: 'textarea' },
                {
                  group: true,
                  title: 'Meine Kontaktdaten',
                  inputs: [
                    { label: 'Emailadresse', type: 'email' },
                    { label: 'Telefonnummer', type: 'text', optional: true },
                  ]
                },
                { label: 'Wann sind Sie gut zu erreichen?', type: 'select', options: ['9-11 Uhr', '11-13 Uhr', '14-16 Uhr', '17-18 Uhr'], value: '9-11 Uhr' },
              ]
            }
          ]
        },
        {
          subject: 'Beratung, Nachricht schreiben & Weiterempfehlung',
          cards: [
            {
              subject: 'Persönliche Beratung vereinbaren',
              email: 'Vielen Dank für Ihre Nachricht. Sie erhalten schnellstmöglich einen Anruf oder eine Email aus unserer Agentur.',
              inputs: [
                { label: 'Art der Versicherung', type: 'select', options: ['Haftpflicht', 'KFZ', 'Tier', 'Hausrat', 'Wohngebäude', 'Unfallversicherung', 'Rechtsschutz', 'Firmenversicherung', 'Rentenversicherung', 'sonstiges'], value: 'nein' },
                { label: 'Beratung zu einem bestehenden Vertrag?', type: 'select', options: ['ja', 'nein'] },
                {
                  group: true,
                  title: 'Meine Kontaktdaten',
                  inputs: [
                    { label: 'Vor- und Nachname', type: 'text' },
                    { label: 'Emailadresse', type: 'email' },
                    { label: 'Telefonnummer', type: 'text', optional: true },
                  ]
                },
                { label: 'Wann sind Sie gut zu erreichen?', type: 'select', options: ['9-11 Uhr', '11-13 Uhr', '14-16 Uhr', '17-18 Uhr'], value: '9-11 Uhr' },
              ]
            },
            {
              subject: 'Nachricht schreiben',
              email: 'Vielen Dank für Ihre Nachricht. Sie erhalten schnellstmöglich einen Anruf oder eine Email aus unserer Agentur.',
              inputs: [
                {
                  group: true,
                  title: 'Meine Kontaktdaten',
                  inputs: [
                    { label: 'Vor- und Nachname', type: 'text' },
                    { label: 'Emailadresse', type: 'email' },
                    { label: 'Telefonnummer', type: 'text', optional: true },
                  ]
                },
                { label: 'meine Nachricht', type: 'textarea' },
              ]
            },
            {
              subject: 'Weiterempfehlung',
              email: 'Vielen Dank für Ihre Weiterempfehlung unserer Agentur. Wir melden uns schnellstmöglich zur gewünschten Beratung. Als Dankeschön erhalten Sie mit dem Rabattcode "Dankeschön" 15 % Sondernachlass auf Ihren nächsten Vertag.',
              inputs: [
                { label: 'Art der Versicherung', type: 'select', options: ['Haftpflicht', 'KFZ', 'Tier', 'Hausrat', 'Wohngebäude', 'Unfallversicherung', 'Rechtsschutz', 'Firmenversicherung', 'Rentenversicherung', 'sonstiges'], value: 'nein' },
                {
                  group: true,
                  title: 'Kontaktdaten der zu beratenden Person',
                  inputs: [
                    { label: 'Vor- und Nachname', type: 'text' },
                    { label: 'Emailadresse', type: 'email' },
                    { label: 'Telefonnummer', type: 'text', optional: true }
                  ]
                },
                {
                  group: true,
                  title: 'Meine Kontaktdaten',
                  inputs: [
                    { label: 'Vor- und Nachname', type: 'text' },
                    { label: 'Emailadresse', type: 'email' },
                    { label: 'Telefonnummer', type: 'text', optional: true },
                  ]
                }
              ]
            }
          ]
        }
      ]
    })

    const masterSubject = computed(() => state.subjects.join(' → '))

    const playBtnSound = () => {
      btnSound.currentTime = 0
      btnSound.volume = 0.2
      const promise = btnSound.play()
      if (promise !== undefined) promise.catch(() => {})
    }

    const fullscreen = () => {
      playBtnSound()
      toggleFullscreen(root.value)
    }

    const setCards = (card) => {
      playBtnSound()
      state.subjects.push(card.subject)
      state.historyCards.push(state.selectableCards)
      state.selectableCards = card.cards

      if (card.inputs) {
        state.form = card
      } else {
        state.form = {}
      }
    }

    const handleHistory = () => {
      playBtnSound()
      state.subjects.pop()
      state.form = {}
      state.selectableCards = state.historyCards.pop()
    }

    const sendForm = () => {
      playBtnSound()
      state.form.masterSubject = masterSubject.value
      axios
        .post(`${process.env.VUE_APP_BACKEND}sendForm.php`,
          state.form,
          {
            headers: {
              'Content-Type': 'application/json; charset=utf-8'
            }
          }
        )
        .then(() => {
          createToast(
            {
              title: 'Ihr Anliegen wurde gesendet!',
              description: state.form.email
            },
            {
              type: 'success',
              position: 'bottom-right',
              showIcon: true,
              timeout: 8000
            }
          )

          state.selectableCards = state.cards
          state.subjects = []
          state.historyCards = []
          state.form = {}

          window.scrollTo({ top: 0, behavior: 'smooth' })
        })
        .catch(error => {
          createToast(
            {
              title: 'Es gab einen Fehler!',
              description: '' + error
            },
            {
              type: 'danger',
              position: 'bottom-right',
              showIcon: true
            }
          )
        })
    }

    onMounted(() => {
      emitter.on('toggle-fullscreen', () => fullscreen())
      emitter.on('play-btn-sound',    () => playBtnSound())

      // define selectable cards
      state.selectableCards = state.cards
    })

    return {
      root,
      state,
      setCards,
      handleHistory,
      sendForm,
      masterSubject
    }
  }
}
</script>

<style lang="scss">
@import '~@/assets/scss/fonts';

$animationDuration: 0.5s; /* Default value: 1s */

@import 'vue2-animate/src/sass/vue2-animate';

:root {
  --app-height: 100%;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  font-family: 'Inter', 'Neue Helvetica', Helvetica, Arial, sans-serif;
  //font-family: Constantia, "Lucida Bright", Lucidabright, "Lucida Serif", Lucida, "DejaVu Serif", "Bitstream Vera Serif", "Liberation Serif", Georgia, serif;
  font-size: calc(4px + 0.75vw + 0.75vh);
  padding: 0;
  margin: 0;
  //touch-action: none;
  user-select: none;
  height: 100vh;
}

*:active,
*:focus {
  outline: none;
  box-shadow: none;
}

.root {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  overflow: hidden;
  background: #dee2e6 url('../src/assets/img/bg.png');

  @media not all and (hover:hover) {
    height: var(--app-height);
  }
}

.container {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  margin: 0 auto;
  height: 100%;
  overflow: auto;
  color: #333;
}

.logo {
  display: block;
  width: 10rem;
  margin: 1rem auto;
}

h1 {
  margin: 2rem 1.5rem 0.5rem 1.5rem;
  color: #311D3F;
  font-weight: 300;
  font-size: 2rem;
  text-align: center;
}

.breadcrumbs {
  margin: 0.5rem 1.5rem 2rem 1.5rem;
  text-align: center;
  font-size: 0.75rem;
}

.card-container {
  position: sticky;
  top: -15rem;
  width: 100%;
  margin-bottom: 1rem;

  &.slideOutLeft {
    position: absolute !important;
    top: unset !important;
  }
}

.card {
  background-color: #544e4e;
  color: #fff;
  margin: 0.5rem 1.5rem;
  padding: 1.75rem 1rem;
  font-size: 1.2rem;
  font-weight: 500;
  cursor: pointer;
  letter-spacing: 0.025rem;

  &.back {
    color: #fff;
    background-color: #E23E57;
    transition: background-color 250ms ease-in-out;

    &:hover {
      background-color: #88304E;
    }
  }
}

.form {
  padding: 1rem 1.5rem;

  h2, h3 {
    margin: 2rem 0 2.5rem 0;
    color: #311D3F;
    font-weight: 600;
    font-size: 1.75rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  .form-group {
    margin: 3rem 0;
  }

  .input-group {
    margin: 1.25rem 0;
  }

  label {
    font-size: 0.85rem;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
  }

  @media (max-width: 720px) {
    select,
    textarea,
    input {
      font-size: 16px !important;
    }
  }

  input, select {
    margin-top: 0.25rem;
    display: block;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ddd;
    font-family: 'Inter', 'Neue Helvetica', Helvetica, Arial, sans-serif;
    font-size: 1rem;
    font-weight: 600;
    padding: 0.75rem;
    border-radius: 0.35rem;
  }

  textarea {
    margin-top: 0.25rem;
    display: block;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ddd;
    font-family: 'Inter', 'Neue Helvetica', Helvetica, Arial, sans-serif;
    font-size: 1rem;
    font-weight: 600;
    padding: 0.75rem;
    border-radius: 0.35rem;
    height: 7.5rem;
  }

  button {
    margin-top: 4rem;
    display: block;
    width: 100%;
    font-family: 'Inter', 'Neue Helvetica', Helvetica, Arial, sans-serif;
    font-size: 1rem;
    font-weight: 600;
    padding: 1rem;
    border-radius: 0.35rem;
    color: #fff;
    background-color: #355C7D;
    border: none;
    cursor: pointer;
    transition: background-color 250ms ease-in-out;

    &:hover {
      background-color: #88304E;
    }
  }

  .mandatory {
    color: #E23E57;
  }

  .form-check {
    display: flex;

    input {
      width: 1.5rem;
      height: 1.5rem;
      margin: 0 1rem 0 0;
    }

    label {
      text-transform: none;
      letter-spacing: 0;

      a {
        color: #E23E57;
      }
    }
  }
}
</style>
